import React, { useState } from 'react';
import useHomeLocation from '@/hooks/useHomeLocations';
import Sider from 'antd/es/layout/Sider';
import { Layout, Menu, MenuProps } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { 
  ApartmentOutlined, 
  AppstoreOutlined, 
  AuditOutlined, 
  BarChartOutlined, 
  BlockOutlined, 
  BuildOutlined, 
  CalculatorOutlined, 
  ContactsOutlined, 
  DeploymentUnitOutlined, 
  DesktopOutlined, 
  GiftOutlined, 
  GlobalOutlined, 
  GroupOutlined, 
  IdcardOutlined, 
  NotificationOutlined, 
  PercentageOutlined, 
  PictureOutlined, 
  RadarChartOutlined, 
  ReconciliationOutlined, 
  RestOutlined, 
  ShopOutlined, 
  SkinOutlined, 
  SnippetsOutlined, 
  TagsOutlined, 
  TeamOutlined, 
  TrophyOutlined, 
  UsergroupAddOutlined, 
  CompassOutlined,
  GoldOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import EBasicActions from '@/enums/EBasicActions';
import EPage from '@/enums/EPages';
import usePermissions from '@/hooks/usePermissions';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import Topbar from '@/containers/Layout/topbar/Topbar';
import useCustomTranslation from '@/hooks/useCustomTranslation';

const MainLayout = () => {
  useHomeLocation();
  const navigate = useNavigate();
  const { t } = useCustomTranslation();
  const { isValidAction } = usePermissions();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(true);

  const {
    Products, 
    Mixes, 
    DeliveryPoints, 
    Providers,
    Categories,
    Warehouses, 
    Solpeds,
    Roles,
    Badges,
    Banners,
    MasterFranchises,
    Franchises,
    Operatives,
    SupplyOrders,
    EntryPlans,
    Deliveries,
    Orders,
    ProductInventories,
    MembershipControl,
    Coupons,
    Discounts,
    Quantifiers,
    Establishments,
    PostalCodes,
    Unit,
  } = EPage;

  const menuOptions: MenuProps['items'] = [];

  const operativeMenuOptions = [];

  if (isValidAction(EBasicActions.Find, Orders)) {
    operativeMenuOptions.push({
      key: '/orders',
      label: t('g.orders'),
      icon: <SnippetsOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Deliveries)) {
    operativeMenuOptions.push({
      key: '/deliveries',
      label: t('g.deliverys'),
      icon: <GlobalOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, ProductInventories)) {
    operativeMenuOptions.push({
      key: '/inventory',
      label: t('g.inventory'),
      icon: <BarChartOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, EntryPlans)) {
    operativeMenuOptions.push({
      key: '/entry_plans',
      label: t('g.entry_plans'),
      icon: <GroupOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, SupplyOrders)) {
    operativeMenuOptions.push({
      key: '/supply_orders',
      label: t('g.supply_orders'),
      icon: <ReconciliationOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Solpeds)) {
    operativeMenuOptions.push({
      key: '/solpeds',
      label: t('g.solpeds'),
      icon: <AuditOutlined />,
    });
  }

  menuOptions.push({
    key: 'operative_menu',
    label: t('g.operatives'),
    children: operativeMenuOptions,
    type: 'group',
  });

  const administrativeMenuOptions = [];

  if (
    isValidAction(EBasicActions.Find, MasterFranchises) 
    || isValidAction(EBasicActions.Find, MasterFranchises) 
    || isValidAction(EBasicActions.Find, MasterFranchises) 
    || isValidAction(EBasicActions.Find, MasterFranchises)
  ) {
    const orgMenuOptions = [];
    
    if (isValidAction(EBasicActions.Find, MasterFranchises)) {
      orgMenuOptions.push({
        key: '/master_franchises/1',
        label: t('g.master_franchise'),
        icon: <RadarChartOutlined />,
      });
    }
  
    if (isValidAction(EBasicActions.Find, Franchises)) {
      orgMenuOptions.push({
        key: '/franchises',
        label: t('g.franchises'),
        icon: <AppstoreOutlined />,
      });
    }
  
    if (isValidAction(EBasicActions.Find, Operatives)) {
      orgMenuOptions.push({
        key: '/operatives',
        label: t('g.operatives'),
        icon: <BuildOutlined />,
      });
    }
  
    if (isValidAction(EBasicActions.Find, Warehouses)) {
      orgMenuOptions.push({
        key: '/warehouses',
        label: t('g.warehouses'),
        icon: <BlockOutlined />,
      });
    }

    administrativeMenuOptions.push({
      key: 'menu_org',
      label: t('g.org'),
      icon: <ApartmentOutlined />,
      children: orgMenuOptions,
    });
  }

  if (
    isValidAction(EBasicActions.Find, Products) 
    || isValidAction(EBasicActions.Find, Products)
  ) {
    const productsMenuOptions = [];

    if (isValidAction(EBasicActions.Find, Products)) {
      productsMenuOptions.push({
        key: '/products',
        label: t('g.submenu_products'),
        icon: <SkinOutlined />,
      });
    }
  
    if (isValidAction(EBasicActions.Find, Mixes)) {
      productsMenuOptions.push({
        key: '/mixes',
        label: t('g.submenu_mixes'),
        icon: <BlockOutlined />,
      });
    }

    administrativeMenuOptions.push({
      key: 'products_menu',
      label: t('g.menu_products'),
      icon: <RestOutlined />,
      children: productsMenuOptions,
    });
  }

  if (
    isValidAction(EBasicActions.Find, Discounts)
    || isValidAction(EBasicActions.Find, Coupons)
  ) {
    const promotionsMenuOptions = [];

    if (isValidAction(EBasicActions.Find, Discounts)) {
      promotionsMenuOptions.push({
        key: '/discounts',
        label: t('g.discounts'),
        icon: <PercentageOutlined />,
      });
    }
  
    if (isValidAction(EBasicActions.Find, Coupons)) {
      promotionsMenuOptions.push({
        key: '/coupons',
        label: t('g.coupons'),
        icon: <TagsOutlined />,
      });
    }

    administrativeMenuOptions.push({
      key: 'promotions_menu',
      label: t('g.promotions'),
      icon: <GiftOutlined />,
      children: promotionsMenuOptions,
    });
  }

  if (
    isValidAction(EBasicActions.Find, Establishments)
  ) {
    const posMenuOptions = [];

    if (isValidAction(EBasicActions.Find, Establishments)) {
      posMenuOptions.push({
        key: '/establishments',
        label: t('g.establishments'),
        icon: <DesktopOutlined />,
      });
    }
  
    posMenuOptions.push({
      key: '/clients',
      label: t('g.clients'),
      icon: <TeamOutlined />,
    });

    administrativeMenuOptions.push({
      key: 'pos_menu',
      label: t('g.pos'),
      icon: <DeploymentUnitOutlined />,
      children: posMenuOptions,
    });
  }

  if (isValidAction(EBasicActions.Find, Roles)) {
    administrativeMenuOptions.push({
      key: '/roles',
      label: t('g.roles'),
      icon: <IdcardOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Categories)) {
    administrativeMenuOptions.push({
      key: '/categories',
      label: t('g.categories'),
      icon: <PictureOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, DeliveryPoints)) {
    administrativeMenuOptions.push({
      key: '/delivery_points',
      label: t('g.delivery_points'),
      icon: <ShopOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Badges)) {
    administrativeMenuOptions.push({
      key: '/badges',
      label: t('g.badges'),
      icon: <TrophyOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Providers)) {
    administrativeMenuOptions.push({
      key: '/providers',
      label: t('g.providers'),
      icon: <ContactsOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, MembershipControl)) {
    administrativeMenuOptions.push({
      key: '/membership_control',
      label: t('g.memberships'),
      icon: <UsergroupAddOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Quantifiers)) {
    administrativeMenuOptions.push({
      key: '/quantifiers',
      label: t('g.quantifiers'),
      icon: <CalculatorOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Unit)) {
    administrativeMenuOptions.push({
      key: '/units',
      label: t('g.units'),
      icon: <GoldOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, Banners)) {
    administrativeMenuOptions.push({
      key: '/banners',
      label: t('g.banners'),
      icon: <NotificationOutlined />,
    });
  }

  if (isValidAction(EBasicActions.Find, PostalCodes)) {
    administrativeMenuOptions.push({
      key: '/postal_codes',
      label: t('g.postal_codes'),
      icon: <CompassOutlined />,
    });
  }

  menuOptions.push({
    key: 'admindividere_menu',
    type: 'divider',
  });

  menuOptions.push({
    key: 'administrative_menu',
    label: t('g.administratives'),
    children: administrativeMenuOptions,
    type: 'group',
  });

  if (isValidAction(EBasicActions.Find, Unit)) {
    administrativeMenuOptions.push({
      key: '/translations',
      label: t('g.translations'),
      icon: <TranslationOutlined />,
    });
  }

  const onClickOption = ({ key }: { key: string }) => {
    navigate(key);
    window.location.reload();
  };

  const footerContent = process.env.REACT_APP_FOOTER;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header 
        style={{ 
          padding: 0, 
          lineHeight: 'normal',
          height: 'auto',
        }}
        className="topbar"
      >
        <Topbar collapsed={collapsed} onClickCollapsed={() => setCollapsed(!collapsed)} />
      </Header>
      <Layout>
        <Sider 
          collapsible 
          trigger={null} 
          style={{ 
            overflow: 'auto',
            background: '#ffffff', 
          }} 
          collapsed={collapsed} 
          onCollapse={value => setCollapsed(value)}
        >
          <Menu 
            theme="light" 
            selectedKeys={[`/${location.pathname.split('/')[1]}`]}
            mode="inline" 
            items={menuOptions} 
            onClick={onClickOption} 
          />
        </Sider>
        <Content style={{ margin: '32px 16px' }}>
          <Outlet />
          {footerContent && (
            <Footer style={{ textAlign: 'center' }}>{footerContent}</Footer>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
