import React, { Suspense, lazy } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import MainWrapper from '@/containers/App/MainWrapper';
import HomePage from '@/containers/Views/Home/HomePage';
import MainLayout from '@/containers/App/Router/MainLayout';
import OperativeRouter from '@/containers/App/Router/SubRouter/OperativeRouter';
import WarehouseRouter from '@/containers/App/Router/SubRouter/WarehouseRouter';
import InventoryRouter from '@/containers/App/Router/SubRouter/InventoryRouter';
import OrdersRouter from '@/containers/App/Router/SubRouter/OrdersRouter';
import CategoriesRouter from '@/containers/App/Router/SubRouter/CategoriesRouter';
import ProviderRouter from '@/containers/App/Router/SubRouter/ProviderRouter';
import FranchiseRouter from '@/containers/App/Router/SubRouter/FranchiseRouter';
import SolpedsRouter from '@/containers/App/Router/SubRouter/SolpedsRouter';
import SupplyOrdersRouter from '@/containers/App/Router/SubRouter/SupplyOrdersRouter';
import RolesRouter from '@/containers/App/Router/SubRouter/RolesRouter';
import ProductsRouter from '@/containers/App/Router/SubRouter/ProductsRouter';
import MixesRouter from '@/containers/App/Router/SubRouter/MixesRouter';
import BadgesRouter from '@/containers/App/Router/SubRouter/BadgesRouter';
import MembershipControlRouter from '@/containers/App/Router/SubRouter/MembershipControlRouter';
import QuantifiersRouter from '@/containers/App/Router/SubRouter/QuantifiersRouter';
import DeliveryRouter from '@/containers/App/Router/SubRouter/DeliveryRouter';
import CouponsRouter from '@/containers/App/Router/SubRouter/CouponsRouter';
import DeliveryPointRouter from '@/containers/App/Router/SubRouter/DeliveryPointRouter';
import DiscountsRouter from '@/containers/App/Router/SubRouter/DiscountsRouter';
import EntryPlansRouter from '@/containers/App/Router/SubRouter/EntryPlansRouter';
import EstablishmentsRouter from '@/containers/App/Router/SubRouter/EstablishmentsRouter';
import MasterFranchiseRouter from '@/containers/App/Router/SubRouter/MasterFranchiseRouter';
import PostalCodesRouter from '@/containers/App/Router/SubRouter/PostalCodesRouter';
import ClientsRouter from '@/containers/App/Router/SubRouter/ClientsRouter';
import { PrivateRoute } from '@/hooks/useAuth';
import BannerRouter from '@/containers/App/Router/SubRouter/BannerRouter';
import UnitsRouter from '@/containers/App/Router/SubRouter/UnitsRouter';
import TranslationRouter from '@/containers/App/Router/SubRouter/TranslationRouter';
// import SeoPagesRouter from '@/containers/App/Router/SubRouter/SeoPagesRouter';
import Loader from './Loader';

const LogIn = lazy(() => import('@/containers/LogIn/index'));
const NotFound404 = lazy(() => import('@/containers/Views/NotFound'));
const Forbidden403 = lazy(() => import('@/containers/Views/Forbidden'));
const PasswordRecovery = lazy(() => import('@/containers/Views/PasswordRecovery/PasswordRecovery'));
const PasswordRecoveryRequest = lazy(
  () => import('@/containers/Views/PasswordRecoveryRequest/PasswordRecoveryRequest'),
);

const Router = () => (
  <MainWrapper>
    <main>
      <Suspense fallback={<Loader />}>
        <Routes>
          
          <Route path="/login" element={<LogIn />} />
          <Route path="/password_recovery" element={<PasswordRecovery />} />
          <Route path="/password_recovery_request" element={<PasswordRecoveryRequest />} />
          <Route path="/404" element={<NotFound404 />} />
          <Route path="/403" element={<Forbidden403 />} />

          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="/home" replace />} />
            <Route 
              path="/home" 
              element={(
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              )} 
            />
            {PostalCodesRouter}
            {OperativeRouter}
            {WarehouseRouter}
            {InventoryRouter}
            {OrdersRouter}
            {CategoriesRouter}
            {ProviderRouter}
            {FranchiseRouter}
            {SolpedsRouter}
            {SupplyOrdersRouter}
            {RolesRouter}
            {ProductsRouter}
            {MixesRouter}
            {BadgesRouter}
            {MembershipControlRouter}
            {QuantifiersRouter}
            {DeliveryRouter}
            {CouponsRouter}
            {DeliveryPointRouter}
            {DiscountsRouter}
            {EntryPlansRouter}
            {EstablishmentsRouter}
            {ClientsRouter}
            {MasterFranchiseRouter}
            {BannerRouter}
            {UnitsRouter}
            {TranslationRouter}
            {/* SeoPagesRouter */}
          </Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense>
    </main>
  </MainWrapper>

);

export default Router;
